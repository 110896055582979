<template>
  <v-container id="user-profile-view" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12">
        <app-card class="mt-4 text-center">
          <v-card-text class="text-center">
            <h4 class="text-h3 mb-3 text--primary font-weight-black">
              {{ selLearner.surname }}, {{ selLearner.gname }}
            </h4>
            <h6 class="text-h5 font-weight-medium text--secondary">
              <v-row>
                <v-col cols="12" md="4"> Age:{{ selLearner.age }} </v-col>
                <v-col cols="12" md="4">
                  Gender: {{ selLearner.gender }}
                </v-col>
                <v-col cols="12" md="4">
                  Location:{{ selLearner.brgy_id }}
                </v-col>
              </v-row>
            </h6>
            <p class="text--secondary">
              {{ selLearner.remarks }}
            </p>
          </v-card-text>
        </app-card>
      </v-col>
      <v-col cols="12">
        <material-card color="primary" icon="mdi-file-table">
          <v-data-table
            :headers="headers"
            :items="assessment"
            :items-per-page="5"
            class="elevation-1"
          ></v-data-table>
        </material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { get } from "vuex-pathify";

export default {
  name: "LearnersAssessment",

  data: () => ({
    headers: [
      { text: "Assessment Tool", value: "tool" },
      { text: "Program", value: "program.name" },
      { text: "Location", value: "brgy.name" },
      { text: "Grade Level", value: "gradeLvl" },
      { text: "Date", value: "_date" },
      { text: "Type", value: "type" },
      { text: "Status", value: "_status" },
      { text: "Value", value: "_value" },
      { text: "Level", value: "_level" },
    ],
  }),

  computed: {
    ...get("learners", ["selLearner"]),
    ...get("assessment", ["assessment"]),
  },
};
</script>
